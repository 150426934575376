<template>
  <div>
    <el-row>
      <el-col>
        <head-layout
          :head-btn-options="headBtnOptions"
          :head-title="$t(`cip.plat.app.bottomMenu.title.indexHeadTitle`)"
          @head-remove="headRemove()"
          @head-add-tabs="headAddTabs"
        ></head-layout>
      </el-col>
    </el-row>
    <grid-head-layout
      ref="gridHeadLayout"
      :grid-head-btn="gridHeadBtn"
      :search-columns="searchColumns"
      @grid-head-search="gridHeadSearch"
      @grid-head-empty="gridHeadEmpty"
    ></grid-head-layout>
    <grid-layout
      ref="gridLayOut"
      @gird-handle-select-click="selectionChange"
      :grid-row-btn="gridRowBtn"
      :table-options="tableOptions"
      :table-data="tableData"
      :table-loading="tableLoading"
      :data-total="page.total"
      :page="page"
      @page-current-change="onLoad"
      @page-size-change="onLoad"
      @page-refresh-change="onLoad"
      @grid-row-detail-click="rowDetail"
      @row-remove="rowRemove"
      @row-edit="rowEdit"
    >
    </grid-layout>
  </div>
</template>

<script>

let baseUrl = '/api/sinoma-system/dict-biz/dictionary?code='
import {mapGetters} from "vuex";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";

import {
  remove,
  getList
} from '@/api/system/bottomMenu'

export default {
  name: "bottomMenu",
  components: {
    GridLayout,
    HeadLayout,
  },
  data() {
    return {
      tableLoading: true,
      tableData: [],
      selectionList: [],
      dataTotal: null,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0
      },
    };
  },
  computed: {
    ...mapGetters(["permission"]),
    searchColumns() {
      return [
        {
          prop: "menuName",
          span: 4,
          maxlength: 20,
          minlength: 2,
          placeholder: this.$t("cip.plat.app.bottomMenu.field.menuName"),
        }
      ]
    },
    tableOptions() {
      return {
        height: 0,
        customAddBtn: true,
        column: [
          {
            label: this.$t('cip.plat.app.bottomMenu.field.menuName'),
            prop: 'menuName',
            isGlobal:true, //是否支持国际化
            width: 400,
            align: "left",
            overHidden: true,
          },
          /*{
            label: this.$t('cip.plat.app.bottomMenu.field.adType'),
            prop: 'adType',
            type: 'select',
            align: "center",
            dataType: "string",
            dicUrl: `${baseUrl}AD_INFO_TYPE`,
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            overHidden: true,
          },*/
          {
            label: this.$t('cip.plat.app.bottomMenu.field.sort'),
            prop: 'sort',
            width: 80,
            align: "right",
            overHidden: true,
          },
          {
            label: this.$t('cip.plat.app.bottomMenu.field.remark'),
            prop: 'remark',
            align: "left",
            overHidden: true,
          },
        ],
      }
    },
    gridRowBtn() {
      let result = [];
      //新增按钮
      if (this.permission.bottomMenu_edit) {
        result.push( {
          label: this.$t('cip.cmn.btn.editBtn'),
          emit: "row-edit",
          type: "button",
          icon: ""
        });
      }
      if (this.permission.bottomMenu_delete) {
        result.push({
          label: this.$t('cip.cmn.btn.delBtn'),
          emit: "row-remove",
          type: "button",
          icon: ""
        });
      }
      return result
    },
    gridHeadBtn() {
      return []
    },
    headBtnOptions() {
      let result = [];
      //新增按钮
      if (this.permission.bottomMenu_add) {
        result.push({
          label: this.$t('cip.cmn.btn.addBtn'),
          emit: "head-add-tabs",
          type: "button",
          icon: "",
          btnOptType: 'add',
        });
      }
      if (this.permission.bottomMenu_delete) {
        result.push({
          label: this.$t('cip.cmn.btn.delBtn'),
          emit: "head-remove",
          type: "button",
          icon: "",
          btnOptType: 'remove',
        });
      }
      return result
    },
    ids() {
      let ids = [];
      this.selectionList.forEach(ele => {
        ids.push(ele.id);
      });
      return ids.join(",");
    }
  },

  mounted() {
    this.onLoad(this.page, {});
  },

  methods: {
    headRemove(){
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.selectWarning'));
        return;
      }
      this.$confirm(this.$t('cip.cmn.msg.warning.delWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return remove(this.ids);
        })
        .then(() => {
          // 刷新表格数据并重载
          this.data = [];
          this.parentId = 0;
          this.$refs.gridLayOut.selectionClear();
          // 表格数据重载
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess"),
          });
        });
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    headAddTabs() {
      this.$router.push({
        path: `/system/bottomMenuEdit`,
        query: {
          type: 'add',
          pageType: 'add',
        }
      })
    },
    rowEdit(row) {
      this.$router.push({
        path: `/system/bottomMenuEdit`,
        query: {
          id: row.id,
          type: 'edit',
          pageType: 'edit',
        }
      })
    },
    rowDetail(row) {
      this.$router.push({
        path: `/system/bottomMenuEdit`,
        query: {
          id: row.id,
          type: 'view',
          pageType: 'detail',
        }
      })
    },
    rowRemove(row) {
      this.$confirm(this.$t('cip.cmn.msg.warning.delWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return remove(row.id);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t('cip.cmn.msg.success.operateSuccess')
          });
        });
    },
    gridHeadSearch(query) {
      this.onLoad(this.page, query)
    },
    gridHeadEmpty(query) {
      this.onLoad(this.page, query)
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      getList(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.$refs.gridHeadLayout.searchForm)
      ).then(res => {
        const data = res.data.data;
        this.$refs.gridLayOut.page.total = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
      });
    }
  }
};
</script>
<style>
</style>
